<template lang="pug">
CanvasModal.download-modal(
  :class="{ 'only-preview': !isProjectPreview, 'rendering-capture-frame-modal' : isCaptureFrame }"
  :is-show="isShow"
  :isCaptureFrame=isCaptureFrame
  :extraClass="!isCaptureFrame ? 'canvas-modal--design' : ''"
  @closeOverlay="closeDownloadPreview"
)
  .download-modal__container
    .download-modal__option(
      :class="{'is-loading' : isLoading}"
      v-if="!isProjectPreview"
    )
      .tab-2
        .download-modal__title
          i.icon-download2
          p.title Download

        .tab__head
          BaseButton.tab__item(
            :class="{ 'is-active': activeTab === 'video', 'has-icon': isFreeUser }"
            @click="changeTab('video')"
          ) Video
          BaseButton.tab__item(
            :class="{ 'is-active': activeTab === 'image' }"
            @click="changeTab('image')"
          ) Image
        .tab__body
          .tab__scene__selection(
            v-if="activeTab === 'video' && showExportSceneDropdownOptions"
          )
            p.label Scenes
            BaseDropdown(
              :options="videoExportSceneSelection"
              :value="videoExportScene"
              @change="updateVideoExportSceneSelection"
            )
          .tab__item(
            :class="{ 'is-active': activeTab === 'video' }"
          )
            template(v-if="isFreeUser")
              BaseDropdown(
                :options="freeDownloadOptions"
                maxWidth="48%"
                @change="updateFreeDownloadSelection"
              )
              .download-modal__buttons.download-modal__buttons--free
                BaseButton(
                  iconBefore="icon-star"
                  @click="removeWatermark"
                  isPrimary=true
                  isTall=true
                ) Remove Watermark
                BaseButton(
                  @click="renderWithWatermark"
                  isPrimaryHollow=true
                  isTall=true
                ) Download with Watermark
            template(v-else)
              .download-modal__buttons
                .btn-container
                  BaseButton.button(
                    :is-primary='true',
                    :is-canvas='true',
                    :is-tall='true',
                    :is-full-width='true',
                    icon-after='icon-down-arrow'
                  ) MP4
                  .btn-container__option(:class='{ "align-left": isFreeUser }')
                    button.btn-option(
                      type='button',
                      @click='processRender("mp4", "high", videoExportScene)'
                    ) High quality
                    button.btn-option(
                      type='button',
                      @click='processRender("mp4", "web", videoExportScene)'
                    ) Web quality
                BaseButton.button(
                  :is-primary='true',
                  :is-canvas='true',
                  :is-tall='true',
                  :is-full-width='true',
                  @click='processRender("mov", "high", videoExportScene)'
                ) MOV
                //-
                  .btn-container
                    BaseButton(
                      isPrimary=true
                      isTall=true
                      iconAfter="icon-down-arrow"
                    ) GIF
                    .btn-container__option(
                        :class="{ 'align-left': isFreeUser }"
                      )
                      button(
                        type="button"
                        @click="processRender('gif', 'high')"
                      ) High quality
                      button(
                        type="button"
                        @click="processRender('gif', 'web')"
                      ) Web quality
              div.complex-project-warning(v-if="showComplexProjectWarning") We notice that you have a complex project, and the rendering process might take longer than usual.
          .tab__item(
            :class="{ 'is-active': activeTab === 'image' }"
          )
            .download-modal__buttons
              template(v-if="!isDownloadingImage")
                BaseButton.button(
                  @click='processRender("jpg")',
                  :is-canvas='true',
                  :is-primary='true',
                  :is-tall='true',
                  :is-full-width='true'
                ) JPG
                BaseButton.button(
                  @click='processRender("png")',
                  :is-canvas='true',
                  :is-primary='true',
                  :is-tall='true',
                  :is-full-width='true'
                ) PNG

              img.downloading-image.preloader-image(
                v-if="isDownloadingImage"
                src="@/assets/images/preloader/offeo_preloader.svg"
              )

    .download-modal__container--preview(:class="{'is-fade-in': isSwitchingTab }")
      .project-preview(
        ref="projectPreviewWrapper"
        :style="projectPreviewStyle"
      )
        BasePreloader.project-preview__preloader(
          v-if="!isCaptureFrame"
          :type="12"
          text="Loading ..."
          :class="{ 'is-show': isLoading }"
        )
        .preview-play__button(v-if='activeTab === "video"')
          button.btn-play-project-preview(
            :id="isCaptureFrame ? 'hidden-play-button' : ''"
            :class="{ 'is-playing': getIsAllScenesPlaying}"
            type="button"
            @click="playAllScenes"
          )
            i.icon.icon-play
          span.btn-play__background(
            :class='[{ "is-playing": getIsAllScenesPlaying}, `is-${proportion}`]'
          )

        .project-preview__container(:class="{ 'is-vertical': proportion === 'vertical' }")
          .preview-project__duration(
            v-show="activeTab === 'video'"
            :class="{ 'is-playing': getIsAllScenesPlaying}"
          )
            i.icon-clock
            span {{ getProjectDetails.duration }}s

          .canvas-scene__overlay(
            v-show="activeTab === 'video'"
            :class="[{ 'is-playing': getIsAllScenesPlaying }, `is-${proportion}`]"
          )

          .project-preview__canvas(
            ref="projectPreviewCanvas"
            :style="canvasStyle"
          )
            template(v-for="(scene, index) in getScenes")
              CanvasScene(
                :id="scene.id"
                :elements="getElements(scene)"
                sceneClass="scenes__canvas"
                :background="scene.background"
                ref="test"
                :animationTimeline="animationTimeline"
                :currentSceneStartTime="currentSceneStartTime(index)"
                :duration="scene.duration"
                :transition="scene.transition"
                isAllScenes=true
                :previousTransition="previousTransition(index)"
                :sceneIndex="index"
                :sceneEntranceTiming="sceneEntranceTiming[index]"
                :isCaptureFrame="isCaptureFrame"
                :projectStartTimeDuration="projectStartTimeDuration"
                :isRenderingAtScale="isRenderingAtScale"
                :hasWatermark="!isCaptureFrame && isFreeUser && activeTab === 'video'"
                @scene-is-set="setScene"
                @scene-elements-is-set="sceneElementsIsSet"
              )

      .slider-container(v-if="!isCaptureFrame" :class="{ 'video-slider': activeTab === 'video' }")
        .range-wrapper
          span.range-before
          input.slider.js-slider(
            type='range',
            step='0.1',
            min='0',
            :max='getAllScenesDuration',
            v-model.number='currentAllScenesTime'
          )
        .slider-container__wrapper(v-show="activeTab === 'image'")
          .slider-container__text(:style='timeStyleObject')
            span.text Frame to export
</template>

<script>
import {
  mapGetters, mapActions, mapMutations, mapState,
} from 'vuex';
import { Linear, TimelineLite } from 'gsap/TweenMax';
import { windowWidth } from '@/assets/scripts/variables';

import CanvasScene from '@/components/ProjectCanvas/CanvasContainer/children/CanvasScene.vue';

import renderMixin from '@/components/mixins/render-mixins';
import helperMixin from '@/components/mixins/helper-mixins';

let getCurrentAllScenesTimeout;

export default {
  name: 'ProjectPreview',
  mixins: [renderMixin, helperMixin],
  data() {
    return {
      downloadModalSize: {
        width: 520,
        height: 520,
      },
      minSize: 520,
      minSizeSmall: 440,
      minSizeSmallest: 280,
      timelineBar: new TimelineLite(),
      isShow: true,
      activeTab: 'video',
      isLoading: true,
      activeSceneIndex: 0,
      totalSceneSet: 0,
      totalSceneElementsSet: 0,
      scenesAnimation: [],
      animationTimeline: 'allScenesTimeline',
      freeDownloadOptions: [
        {
          value: 'mp4-web',
          name: 'MP4 (Web Quality)',
        },
        {
          value: 'mp4-high',
          name: 'MP4 (High Quality)',
        },
        {
          value: 'mov',
          name: 'MOV',
        },
      ],
      freeDownloadType: 'mp4',
      freeDownloadQuality: 'web',
      isSwitchingTab: false,
      videoExportScene: 'all',
      videoExportSceneSelection: [
        {
          value: 'all',
          name: 'All Scenes',
        },
        {
          value: 'separate',
          name: 'All scenes separately',
        },
      ],
    };
  },
  props: {
    isCaptureFrame: { type: Boolean, default: false },
    scaleDimension: { type: Number, default: -1 },
    isRenderingAtScale: { type: Boolean, default: false },
    projectStartTimeDuration: { type: Number, default: 0 },
  },
  components: {
    CanvasScene,
  },
  computed: {
    ...mapState('canvasElements', ['timelineSpeed', 'scenesList']),
    ...mapGetters([
      'isDownloadPreviewOpened',
      'getDownloadPreviewOption',
      'getDownloadPreviewType',
      'isProjectPreview',
      'getIsAllScenesPlaying',
    ]),
    ...mapGetters('userData', ['isFreeUser']),
    ...mapGetters('canvasElements', [
      'getScenes',
      'getScenesElementsById',
      'getCanvasSize',
      'getAllScenesTimeline',
      'getAllScenesDuration',
      'getProjectDetails',
    ]),
    ...mapGetters('exportDetails', ['getExportDetailStates']),
    proportion() {
      if (this.getCanvasSize.width > this.getCanvasSize.height) return 'landscape';
      if (this.getCanvasSize.width < this.getCanvasSize.height) return 'vertical';
      return 'square';
    },
    canvasModalStyle() {
      const styleObject = {};

      if (this.isCaptureFrame) {
        styleObject.width = `${this.getCanvasSize.width}px`;
        styleObject.height = `${this.getCanvasSize.height}px`;
      }

      return styleObject;
    },
    canvasStyle() {
      const styleObject = {};

      const { width, height } = this.downloadModalSize;

      let sceneScale;
      let canvasWidth = this.getCanvasSize.width;
      let canvasHeight = this.getCanvasSize.height;

      if (this.scaleDimension > 1) {
        sceneScale = this.scaleDimension;
      } else {
        sceneScale = height / this.getCanvasSize.height;
        if (this.getCanvasSize.width * sceneScale >= width) {
          sceneScale = width / this.getCanvasSize.width;
        }
      }

      if (this.isCaptureFrame) {
        if (sceneScale < 1) {
          sceneScale = 1;
        } else {
          canvasWidth *= this.scaleDimension;
          canvasHeight *= this.scaleDimension;
        }
      }

      let offsetLeft = (canvasWidth * sceneScale) / 2;
      let offsetTop = (canvasHeight * sceneScale) / 2;

      // maximize full with without scaling the wrapper
      if (this.isCaptureFrame) {
        offsetLeft = 0;
        offsetTop = 0;
        styleObject.left = 0;
        styleObject.top = 0;
      }

      styleObject.marginLeft = `-${offsetLeft}px`;
      styleObject.marginTop = `-${offsetTop}px`;
      styleObject.width = `${canvasWidth}px`;
      styleObject.height = `${canvasHeight}px`;
      styleObject.transform = `scale(${sceneScale})`;

      if (!this.isCaptureFrame && sceneScale > 1) {
        styleObject.marginLeft = 0;
        styleObject.marginTop = 0;
        styleObject.transformOrigin = 'top center';
        styleObject.left = 0;
        styleObject.right = 0;
        styleObject.top = 0;
        styleObject.bottom = 0;
      }

      return styleObject;
    },
    timeStyleObject() {
      const styleObject = {};
      const percentage = this.currentAllScenesTime / this.getAllScenesDuration;

      let left = percentage * 100;
      // Manually make it centered
      if (left > 15 && left < 40) left -= 2;
      if (left > 60 && left > 75) left += 2;

      if (left < 15) left -= 4;
      if (left >= 75) left += 1;

      styleObject.left = `${left}%`;

      return styleObject;
    },
    projectPreviewStyle() {
      if (this.isCaptureFrame) return;
      const styleObject = {};

      const projectPreviewWidth = this.downloadModalSize.width;
      const projectPreviewHeight = this.downloadModalSize.height;
      styleObject.width = `${projectPreviewWidth}px`;
      styleObject.height = `${projectPreviewHeight}px`;

      if (this.proportion === 'square') styleObject.transform = 'scale(0.95)';

      return styleObject;
    },
    imageSliderStyle() {
      const styleObject = {};

      const sliderWidth = this.downloadModalSize.width - 70;
      styleObject.width = `${sliderWidth}px`;

      return styleObject;
    },
    sceneEntranceTiming() {
      const scenesTiming = [];
      let prevDuration = 0;
      for (let i = 0; i < this.getScenes.length; i += 1) {
        const { duration } = this.getScenes[i];
        // console.log('duration', duration);
        scenesTiming.push(prevDuration + duration);
        prevDuration += duration;
      }
      return scenesTiming;
    },
    showExportSceneDropdownOptions() {
      return process.env.VUE_APP_EXPORT_VERSION === 'v2';
    },
    showComplexProjectWarning() {
      return this.getAllScenesDuration >= 60 || this.scenesList?.allIds?.length >= 10;
    },
  },
  methods: {
    ...mapMutations([
      'setCurrentAllScenesTime',
      'isAllScenesPlaying',
      'setIsRendering',
      'setShowUpgradeSubscription',
      'setActiveSceneIndex',
    ]),
    ...mapActions('animationSettings', ['setAllTransition']),
    changeTab(value) {
      this.activeTab = value;
    },
    getElements(scene) {
      if (!scene || !scene.id) return [];
      return [...this.getScenesElementsById(scene.id)].reverse();
    },
    updateDownloadModalSize() {
      // todo: remove timeout
      setTimeout(() => {
        const { width, height } = this.getCanvasSize;
        // for capture frame toggle full width and height
        this.downloadModalSize.width = this.isCaptureFrame
          ? width
          : this.$refs.projectPreviewWrapper.clientWidth;
        this.downloadModalSize.height = this.isCaptureFrame
          ? height
          : this.$refs.projectPreviewWrapper.clientHeight;
      }, 10);
    },
    currentSceneStartTime(index) {
      // eslint-disable-next-line
      if (index == 0) return 0;
      let duration = 0;
      this.getScenes.forEach((scene, sceneIndex) => {
        if (sceneIndex < index) {
          duration += scene.duration;
        }
      });
      return duration;
    },
    previousTransition(index) {
      if (index === 0) return {};
      return this.getScenes[index - 1].transition;
    },
    playAllScenes() {
      /**
       * DON't alter id of play button '#hidden-play-button' as it will be used as a trigger handler for playing in rendering servers
       * document.getElementById('hidden-play-button').click()
       */

      const currentAnimationTime = this.getCurrentAllScenesTime;

      this.timelineBar.seek(currentAnimationTime);
      this.getAllScenesTimeline.seek(currentAnimationTime);

      if (this.getIsAllScenesPlaying) {
        this.isAllScenesPlaying(false);
        this.timelineBar.timeScale(this.timelineSpeed).pause();
        this.getAllScenesTimeline.timeScale(this.timelineSpeed).pause();
        this.$root.$emit('pauseAudio');
        this.$emit('play-all-scenes', false);
      } else {
        this.isAllScenesPlaying(true);
        this.timelineBar
          .timeScale(this.timelineSpeed)
          .seek(currentAnimationTime)
          .play();
        this.getAllScenesTimeline
          .timeScale(this.timelineSpeed)
          .seek(currentAnimationTime)
          .play();

        const completeTime = () => {
          this.resetCanvasPreview();
          console.log('play all scenes');
        };

        this.getAllScenesTimeline.eventCallback('onComplete', completeTime);

        this.$root.$emit('playAudio');
        this.$emit('play-all-scenes', true);
      }
    },
    setTimelineControllerTimeline(seekMiddleDuration = false) {
      const timeObj = { time: 0 };
      const totalDuration = this.getAllScenesDuration;
      let startTime = 0;
      if (seekMiddleDuration) {
        startTime = parseFloat(totalDuration / 2);
      }
      this.setCurrentAllScenesTime(startTime);

      this.timelineBar.clear();
      this.timelineBar.addLabel('Start', startTime);
      this.timelineBar.pause();
      this.getAllScenesTimeline.clear();
      this.getAllScenesTimeline.restart();
      this.getAllScenesTimeline.addLabel('Start', startTime);
      this.getAllScenesTimeline.pause();

      const updateTime = () => {
        this.setCurrentAllScenesTime(parseFloat(timeObj.time.toFixed(1)));
      };

      const completeTime = () => {
        // console.log('captureframe -- complete time - setTimelineControllerTimeline');
        setTimeout(() => {
          this.resetCanvasPreview();
        }, 1500);
      };

      this.timelineBar.to(
        timeObj,
        totalDuration,
        {
          time: `+=${totalDuration}`,
          onUpdate: updateTime,
          onComplete: completeTime,
          ease: Linear.easeNone,
        },
        'Start',
      );

      // added timeout to make sure the tweenmax already set
      setTimeout(() => {
        this.timelineBar.seek(startTime);
        this.getAllScenesTimeline.seek(startTime);
      }, 200);
    },
    resetCanvasPreview() {
      // console.log('captureframe -- resetCanvasPreview');
      this.timelineBar.pause();
      this.timelineBar.seek(0);
      this.getAllScenesTimeline.pause();
      this.getAllScenesTimeline.seek(0);
      this.$root.$emit('canvas-element-animation-updated');
      this.setCurrentAllScenesTime(0);
      this.isAllScenesPlaying(false);
      this.$root.$emit('pauseAudio');
    },
    setScene(scene) {
      this.totalSceneSet += 1;
      // console.log('setScene', scene);
      // console.log('setElement', this.totalElementSet);
      this.scenesAnimation[scene.id] = scene;
    },
    sceneElementsIsSet() {
      this.totalSceneElementsSet += 1;
    },
    removeWatermark() {
      this.downloadAfterSubscribe = true;
      this.setIsDownloadPreviewOpened(false);
      this.setShowUpgradeSubscription(true);
    },
    updateFreeDownloadSelection(selection) {
      const splitSelection = selection.split('-');
      const [type, quality] = splitSelection;
      this.freeDownloadType = type;
      this.freeDownloadQuality = quality;
    },
    renderWithWatermark() {
      if (typeof this.freeDownloadQuality !== 'undefined') {
        this.processRender(this.freeDownloadType, this.freeDownloadQuality);
      } else {
        this.processRender(this.freeDownloadType);
      }
    },
    updateVideoExportSceneSelection(value) {
      this.videoExportScene = value;
    },
  },
  beforeMount() {
    this.isShow = this.isCaptureFrame || this.getExportDetailStates.isExporting || this.isDownloadPreviewOpened;

    this.activeTab = 'video';

    console.log(
      'window.screen.width <= windowWidth.fbRequirement',
      window.screen.width,
      windowWidth.fbRequirement,
      this.minSizeSmallest,
    );

    if (window.screen.width <= windowWidth.fbRequirement) {
      this.downloadModalSize.width = this.minSizeSmallest;
      this.downloadModalSize.height = this.minSizeSmallest;
    } else if (window.screen.width <= windowWidth.smallLaptopSize) {
      this.downloadModalSize.width = this.minSizeSmall;
      this.downloadModalSize.height = this.minSizeSmall;
    } else {
      this.downloadModalSize.width = this.minSize;
      this.downloadModalSize.height = this.minSize;
    }
  },
  mounted() {
    this.updateDownloadModalSize();
    this.setTimelineControllerTimeline();

    const canvasSize = this.$refs.projectPreviewCanvas;
    const canvasWidth = canvasSize.getBoundingClientRect().width;
    const canvasHeight = canvasSize.getBoundingClientRect().height;

    if (canvasWidth) this.downloadModalSize.width = canvasWidth + 3;
    if (canvasHeight) this.downloadModalSize.height = canvasHeight + 3;

    // hide the loader after 1 sec
    setTimeout(() => {
      this.isLoading = false;
      this.activeTab = this.getDownloadPreviewOption;
    }, 1000);

    console.log('sceneEntranceTiming', this.sceneEntranceTiming);

    // EXPORT SERVER MAINTENANCE ANNOUNCEMENT
    // if (!this.isProjectPreview && !this.isCaptureFrame) {
    //   this.alertWarn('Notification', `There will be server maintenance for OFFEO from 10am-12pm (SGT) on 28th November 2023.
    //   OFFEO will still be available for usage but you will not be able to download your videos until the maintenance is done.
    //   We apologise for the inconvenience caused`, 10000);
    // }

    this.$root.$on('playAllScenes', () => {
      this.playAllScenes();
    });
  },
  destroyed() {
    this.$root.$off('playAllScenes');
  },
  watch: {
    getCurrentAllScenesTime(val) {
      // console.log('scenesList', this.scenesList);
      // console.log('getCurrentAllScenesTime', this.sceneEntranceTiming);

      // populate necessary elements for active scenes only
      let activeSceneIndex = 0;
      // if (val < this.sceneEntranceTiming[0]) {
      //   activeSceneIndex = 0;
      // }
      for (let i = 0; i < this.sceneEntranceTiming.length; i += 1) {
        if (this.sceneEntranceTiming[i] > val) {
          activeSceneIndex = i;
          break;
        }
      }

      if (!this.getIsAllScenesPlaying) {
        let currentAnimationTime = this.getCurrentAllScenesTime;
        if (currentAnimationTime === 0) {
          currentAnimationTime = 0.05;
        }
        this.getAllScenesTimeline.seek(currentAnimationTime);
        clearTimeout(getCurrentAllScenesTimeout);
        // do play pause to trigger the text animation properly
        getCurrentAllScenesTimeout = setTimeout(() => {
          this.getAllScenesTimeline
            .seek(currentAnimationTime)
            .play()
            .pause();
        }, 500);
      }

      // console.log('this.getAllScenesTimeline', val, this.getAllScenesTimeline);

      // console.log('activeSceneIndex', val, this.sceneEntranceTiming, activeSceneIndex);

      this.setActiveSceneIndex(activeSceneIndex);
    },
    // getAllScenesDuration() {

    //   console.log('watch this isShow ', this.isShow, this.getIsAllScenesPlaying );
    //   clearTimeout(allScenesTimeout);
    //   allScenesTimeout = setTimeout(() => {
    //     this.setTimelineControllerTimeline();
    //   }, 500);
    // },
    activeTab(newVal, oldVal) {
      if (newVal !== oldVal) this.isSwitchingTab = true;

      if (newVal === 'image') {
        this.isAllScenesPlaying(false);
        this.timelineBar.timeScale(this.timelineSpeed).pause();
        this.getAllScenesTimeline.timeScale(this.timelineSpeed).pause();
        this.$root.$emit('pauseAudio');
        // this.setTimelineControllerTimeline(true);
        this.setCurrentAllScenesTime(parseFloat(this.getAllScenesDuration / 2).toFixed(1));
      } else {
        this.setCurrentAllScenesTime(0);
      }
    },
    isSwitchingTab(val) {
      if (val) {
        setTimeout(() => {
          this.isSwitchingTab = false;
        }, 500);
      }
    },
    totalSceneSet(val) {
      if (val === this.getScenes.length) {
        this.setAllTransition({
          activeTimeline: this.animationTimeline,
          scenes: this.scenesAnimation,
        });

        this.totalSceneSet = 0;

        this.$nextTick(() => {
          const currentAnimationTime = this.getCurrentAllScenesTime;
          this.getAllScenesTimeline.seek(
            currentAnimationTime <= 0.03 ? 0.03 : currentAnimationTime,
          );
        });
      }
      if (val === 0) {
        this.scenesAnimation = {};
      }
    },
    totalSceneElementsSet(val) {
      if (val === this.getScenes.length) {
        this.$emit('all-animation-set');
        this.totalSceneElementsSet = 0;
      }
    },
  },
};
</script>

<style lang="scss">
// rendering capture frame css start
.rendering-capture-frame-modal {
  margin: 0;
  padding: 0;
  right: auto;
  display: block !important;

  .canvas-modal__overlay,
  .canvas-modal__header,
  .canvas-modal__closebtn {
    display: none;
  }

  .canvas-modal__content {
    max-width: 100% !important;
    max-height: 100% !important;
    background: transparent !important;
    overflow: hidden;
  }
  .canvas-modal__main {
    .content {
      padding: 0;
      overflow: hidden;

      .download-modal__container {
        display: block;

        .tab-2 {
          height: auto;
          width: auto;
        }
      }

      .project-preview {
        width: auto;
        height: auto;
        transform: scale(1) !important;
      }

      .project-preview__container {
        background: transparent;
        opacity: 1;
      }

      .btn-play-project-preview,
      .btn-play__background {
        opacity: 0;
      }
    }
  }

  &.download-modal {
    .download-modal__option {
      display: none;
    }

    .download-modal__container--preview {
      height: 100%;
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .canvas-modal__content {
      width: auto;
      height: auto;
    }
  }

  .project-preview__container {
    height: 100%;
    width: 100%;

    .preview-project__duration,
    .canvas-scene__overlay {
      display: none;
    }
  }
}
// rendering capture frame css end

.download-modal {
  .canvas-modal__overlay {
    background: rgba(0, 0, 0, 0.9);
  }

  .canvas-modal__closebtn {
    color: $light !important;
  }

  .canvas-modal__content {
    height: 680px;
    background: $darkGrey700;
    border-radius: 10px;
  }

  .canvas-modal__main {
    .content {
      padding: 0;
      overflow: hidden;
    }
  }

  .download-modal__container--preview {
    display: flex;
    place-content: center;
    align-items: center;
    flex-direction: column;
    width: 55%;
    height: 100%;
    padding: 30px 0;

    &.is-fade-in {
      animation: fade-in 1s;
    }

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }

    .preview-play__button {
      position: absolute;
      width: 100%;
      height: 100%;
      transition: 0.3s;

      &:hover {
        .btn-play-project-preview {
          color: $light;
        }

        .btn-play__background {
          background: $blue;
        }
      }
    }

    .btn-play__background {
      position: absolute;
      top: 45%;
      left: 45%;
      width: 45px;
      height: 45px;
      background: $light;
      border-radius: 50%;
      z-index: 2;

      &.is-playing {
        opacity: 0;
      }

      &.is-vertical {
        left: 41%;
      }

      &.is-landscape {
        top: 42%
      }
    }

    .slider-container {
      width: 55%;
      height: 48px;
      position: relative;
      margin: 60px 0 0 0;

      &.video-slider {
        margin-top: 20px !important;
      }

      .range-wrapper {
        max-width: 100%;

        .range-before {
          top: 40px;
        }
      }

      .range-before,
      .range-before::after {
        height: 2px;
        background: $timelineRuler;
      }

      input[type='range'] {
        margin: 0;
        margin-top: 25px;
        height: 55px;

        &::-moz-range-thumb,
        &::-ms-thumb {
          height: 16px;
          width: 16px;
          border-radius: 50%;
        }

        &::-webkit-slider-thumb {
          margin-top: -16px;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          background: $light;
        }
      }

      .slider-container__wrapper {
        position: absolute;
        height: 40px;
        width: calc(100% - 40px);
        top: 8px;
        left: 0;
        pointer-events: none;
        z-index: 2;
      }

      .slider-container__text {
        position: absolute;
        width: 40px;
        display: block;
        top: 0;
        left: 0;

        .text {
          width: 130px;
          top: -40px;
          left: -38px;
          font-weight: 500;
          background: $blue3;
          color: $light;
          border-radius: 5px;
          padding: 7px;

          &::after {
            content: '';
            position: absolute;
            display: block;
            border-width: 12px 7px 0;
            border-style: solid;
            border-color: $blue3 transparent transparent;
            bottom: -12px;
            left: 46%;
          }
        }

        span {
          position: absolute;
          top: 50px;
          width: 56px;
          margin-left: -8px;
          text-align: center;
          font-size: 0.875em;
          color: var(--black-white);
        }
      }
    }
  }
}

.download-modal__container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;

  .tab-2 {
    background: none;
    margin: auto 0;
  }

  .download-modal__title {
    display: flex;
    color: $light;
    font-weight: 600;
    font-size: 20px;

    .title {
      margin-left: 18px;
    }
  }

  .tab__head {
    padding: 0;
    background: none;
    justify-content: flex-start;

    .tab__item {
      font-size: 18px;
      width: fit-content;
      background: none;
      flex: none;
      padding: 18px 0 5px;
      box-shadow: none;
      text-align: left;
      margin-right: 20px;

      &:hover,
      &.is-active {
        color: $light;
        font-weight: 600;
        background: none;
      }

      &.is-active {
        border-bottom: 2px solid $green700;
      }
    }
  }

  .tab__body {
    margin-top: 15px;

    .tab__item {
      padding: 20px 20px 20px 0;
    }

    .tab__scene__selection {
      display: flex;
      align-items: center;

      .label {
        color: #fff;
      }

      .dropdown {
        margin-left: 20px;
        width: 50%;
        z-index: 20;
        margin-bottom: 0;

        & > button,
        & > ul {
          background: $darkGrey500;
          border-color: $darkGrey500;
          color: #fff;
        }

        .dropdown__item {
          &.is-selected,
          &:hover {
            background: $green600;
          }
          border-bottom: none;
        }
      }
    }
  }

  .icon-star {
    color: $yellow;
    display: inline-block;
    vertical-align: middle;
    margin-left: 0;
    margin-right: 7px;
  }
}

.download-modal__option,
.project-preview {
  height: 520px;

  @include smallest {
    height: 440px;
  }

  @include fb-requirement {
    height: 280px;
  }
}

.project-preview {
  position: relative;
  overflow: hidden;
  width: 520px;

  @include smallest {
    width: 350px;
  }

  @include fb-requirement {
    width: 280px;
  }

  .scenes__canvas {
    transform-origin: center center;
  }

  .watermark-top,
  .watermark-bot {
    height: 150px;
    width: 150px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 1;

    @include smallest {
      height: 120px;
      width: 120px;
    }
  }

  .watermark-top {
    background-image: url('~@/assets/images/watermark/video-top-preview.png');
    top: 0;
    left: 0;
  }
  .watermark-bot {
    background-image: url('~@/assets/images/watermark/video-bot-preview.png');
    bottom: 0;
    right: 0;
  }
}

.btn-go-choose-frame {
  margin-bottom: 30px;
}

.project-preview__container {
  position: relative;
  width: 100%;
  height: 100%;

  &.is-vertical {
    width: 99%;
  }

  .preview-project__duration {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    z-index: 1;
    color: $light;
    background: $darkGrey5;
    padding: 8px;
    border-radius: 4px;
    pointer-events: none;
    box-shadow: 5px 5px 5px 3px rgba($black, 0.3);
    opacity: 1;

    &.is-playing {
      opacity: 0;
    }

    .icon-clock {
      font-size: 15px;
    }

    span {
      font-size: 14px;
      margin-left: 8px;
    }
  }

  .canvas-scene__overlay {
    position: absolute;
    bottom: 0;
    left: -1px;
    margin: 0;
    width: 101%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    z-index: 1;

    &.is-playing {
      display: none;
    }

    &.is-vertical {
      left: -2px;
    }

    &.is-landscape {
      bottom: 1px;
    }
  }
}

.project-preview__canvas {
  position: relative;
  display: inline-block;
  user-select: none;
  overflow: hidden;
  transform-origin: top left;
  left: 50%;
  top: 50%;
  background: transparent;
  transition: background 0.35s ease-in-out;
  border-radius: 4px;

  .scene {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .drr.active {
    &::before {
      display: none;
    }
  }
}

.project-preview__preloader {
  position: absolute;
  z-index: 4;
  width: 100%;
  background: $darkGrey500;
  transition: opacity 0.25s ease-in-out;

  &:not(.is-show) {
    pointer-events: none;
    opacity: 0;
  }

  .preloader__text {
    color: $light;
    font-size: 1em;
    font-weight: 400;
  }
}

.btn-play-project-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 78px;
  cursor: pointer;
  z-index: 3;
  color: $blue;

  &.is-playing {
    opacity: 0;
  }

  &.is-show {
    &:not(.is-playing) {
      & ~ .project-preview__container {
        opacity: 0.4;
      }
    }
  }
}

.download-modal__option {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
  padding: 30px 0 30px 70px;
  background: $darkGrey5;

  &.is-loading {
    pointer-events: none;
    opacity: 0.2;
  }

  .title {
    margin-top: 0;
    font-weight: 600;
  }

  .dropdown {
    margin-bottom: 20px;

    .dropdown__list {
      position: absolute;
      width: 100%;
    }
  }
}

.download-modal__buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.download-modal__buttons--free {
    button {
      max-width: 48%;
      padding-left: 8px;
      padding-right: 8px;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  > * {
    margin-right: 15px;
    flex: 1 33%;
    max-width: 33%;
  }

  .downloading-image {
    max-width: 13%;
  }

  .btn-container {
    position: relative;

    .button {
      .icon {
        position: absolute;
      }
    }

    &:hover .btn-container__option {
      opacity: 1;
      visibility: visible;
    }
  }

  .btn-container__option {
    position: absolute;
    padding: 5px;
    background: $darkGrey900;
    box-shadow: 0 0px 15px rgba(50, 50, 50, 0.2);
    text-align: left;
    font-size: 0.875em;
    z-index: 3;
    border-radius: 4px;
    opacity: 0;
    visibility: hidden;

    &.align-left {
      button {
        text-align: left;
      }
    }

    .btn-option {
      padding: 12px 11px;
      width: 100%;
      color: $light;

      &:hover {
        color: $green700;
      }
    }
  }
}

.is-hero-element {
  img {
    object-fit: contain;
  }
}

.complex-project-warning {
  background: $btnAlertBackground;
  color: $textBlack;
  margin-top: 20px;
  padding: 5px;
  border-radius: 5px;

  &:focus {
    background: $btnAlertBackground;
  }
}
</style>
